// zabezpieczenie stron które są dostępne tylko zalogowanych licytantów

import { useUserAuthStore } from '~coreStores/user-auth.store';

import { redirectToLoginAsBidder } from '~coreUtils/redirect-to-bidder-login';

export default defineNuxtRouteMiddleware((to) => {
  // middleware tylko uruchamiany w trybie client - naprawia błąd w przypadku stron, które nie mają ustawione ssr: false w nuxt configu (np net-payment.vue) i używają tego middleware
  if (!import.meta.client) return;

  const { $routeNames } = useNuxtApp();

  const userAuthStore = useUserAuthStore();
  const { getBidderUserData } = storeToRefs(userAuthStore);

  // jeśli licytant nie jest zalogowany
  if (!getBidderUserData.value) {
    // jeśli chcemy przekierować na stronę logowania i zapisać ścieżkę do powrotu na żądaną stronę po zalogowaniu
    if (to.meta.redirectToLogin) {
      redirectToLoginAsBidder(to.fullPath);
      return;
    }

    return navigateTo({ name: $routeNames.index });
  }
});
